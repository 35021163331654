import "reflect-metadata";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";

Promise.all([import("./Root"), import("./App")]).then(
  ([{ default: render }, { default: App }]) => {
    render(App);
  }
);

// ts(1208)
export {};
